import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBacteria,
  faBandAid,
  faBug,
  faCarrot,
  faCloudsSun,
  faCrutch,
  faCut,
  faEye,
  faFaucet,
  faHeartbeat,
  faMindShare,
  faPills,
  faPoop,
  faSkullCrossbones,
  faTurkey,
  faUser,
  faUserMd,
  faWeight,
  faWhistle,
} from '@fortawesome/pro-light-svg-icons';

library.add({
  faBacteria,
  faBandAid,
  faBug,
  faCarrot,
  faCloudsSun,
  faCrutch,
  faCut,
  faEye,
  faFaucet,
  faHeartbeat,
  faMindShare,
  faPills,
  faPoop,
  faSkullCrossbones,
  faTurkey,
  faUser,
  faUserMd,
  faWeight,
  faWhistle,
});

export default {};
